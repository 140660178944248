import { useCallback } from "react";

export const useFetch = (): ((
  ...args: Parameters<typeof fetch>
) => ReturnType<typeof fetch>) => {
  // token management
  // const { token } = useUserProfile();
  const token = import.meta.env.VITE_AUTH_TOKEN;
  const serverUrl = import.meta.env.VITE_SERVER_URL || "";

  return useCallback(
    async (...args: Parameters<typeof fetch>) => {
      const [input, requestInit] = args;

      const headers: HeadersInit = {
        "Content-Type": "application/json",
        "X-Page-Referer": window.location.pathname,
      };

      if (token) {
        headers["Authorization"] = `${token}`;
      }

      return fetch(serverUrl + input, {
        mode: "cors",
        ...requestInit,
        headers: {
          ...requestInit?.headers,
          ...headers,
        },
      });
    },
    [token]
  );
};
