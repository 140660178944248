import { useState } from "react";
import reactLogo from "./assets/react.svg";
import viteLogo from "/vite.svg";
import { useQuery } from "react-query";
import { useFetch } from "@src/utils/useFetch";
import QueryKeys from "@src/constants/QueryKeys";

import "./App.css";

function App() {
  const [count, setCount] = useState(0);
  const configuredFetch = useFetch();
  // fetch("http://text-to-speech-app:3000/api/helloworld");
  const { data: helloWorld, isFetching: isLoading } = useQuery(
    QueryKeys.HelloWorld,
    async () => {
      const res = await configuredFetch("/api/helloworld/");

      return res.json();
    },
    {
      refetchOnMount: true,
    }
  );

  return <>Hello world</>;
}

export default App;
