import { FC, ReactNode } from "react";
import isNetworkError from "is-network-error";
import { useMemo } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

const genericMessage = "An error occurred while the system was running.";

interface Props {
  children: ReactNode;
}

export const QueryClientProviderWrapper: FC<Props> = ({ children }) => {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            // @TODO make it false
            refetchOnReconnect: true,
            // @TODO make it true
            refetchOnMount: false,
            keepPreviousData: false,
            staleTime: 30_000,
            onError: (err) => {
              console.error(err);
            },
            retry: (failureCount, error) => {
              // retry only in case if it's network error — for example, the user entered the subway metro tunnel and was lost
              // CORS error, invalid URL and backend responses are not considered network errors
              if (isNetworkError(error)) {
                return failureCount < 3;
              }
              return false;
            },
          },
          mutations: {
            onError: (err: any) => {
              console.error(err.message || genericMessage);
            },
          },
        },
      }),
    []
  );

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
